<template>
  <div>
    <div class="mt-5">
      <div class="pr-3">
        <!-- input section -->
        <div class="inputURLSection sm:pl-3 w-100 mt-2 relative">
          <form class="mt-3">
            <label
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-once="true"
              for="urlInput"
            >Question</label>
            <div class="input-group mb-3 text-small">
              <input
                data-aos="fade-up"
                data-aos-delay="350"
                data-aos-once="true"
                ref="fileInput"
                v-model="inputQuestion"
                type="text"
                class="form-control"
                @keypress.enter.prevent
                placeholder="Please ask your question"
                maxlength="99"
                aria-label="imageurl"
                @change.prevent="onUrlSubmit()"
              />
            </div>
          </form>
        </div>
        <!-- end of input url -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      questionData: "",
      inputQuestion: ""
    };
  },
  methods: {
    onUrlSubmit() {
      this.questionData = this.inputQuestion;
      this.$emit("selected-input", this.questionData);
    }
  },

  props: {
    modelinput: Object,
    isLoading: Boolean
  }
};
</script>

<style lang="scss" scoped>
::placeholder {
  font-size: 12px;
}
input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

@media (max-width: 500px) {
  .inputURLSection {
    margin-top: 1em;
  }
}
</style>