<template>
  <div class="exclude-navbar face-compare-page">
    <container-section>
      <h1
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-once="true"
        class="text-center"
      >Chat GPT-3</h1>
      <p
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-once="true"
        class="pt-3 md:w-4/5 m-auto text-center"
      >Chat gpt-3 can understand, and respond to user questions in corresponds to given context or paragraphs, predict needs based on a single word and enhance their human-like responses with every interaction. Tap on Submit to try now!</p>
      <question-answering-test-section
        v-on:on-passcode-valid="questionAnswer($event)"
        :result="responseData"
        :isLoading="isLoading"
        :requestMade="userRequest"
      />

      <!-- try new models section -->
      <div>
        <common-carousel />
      </div>
      <!-- end of try new models section -->

      <!-- Cards section -->
      <div class="row d-none">
        <div class="flex col-6 col-md-4 col-lg-3" v-for="card in applicationCards" :key="card.id">
          <details-card :title="card.title" :description="card.description" :to="card.route" />
        </div>
      </div>
    </container-section>

    <container-section :containerClass="'container-fluid'" :backgroundColor="'#fff'">
      <!-- FAQ's Section -->
      <div class="container mt-10 py-24">
        <h2
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-once="true"
          class="my-5"
        >Frequently asked questions</h2>
        <question-drop-down
          v-for="(question,index) of questionsData"
          :key="index"
          :title="question.question"
          :answer="question.answer"
        />
      </div>
    </container-section>

    <div class="about-footer relative">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import QuestionAnsweringTestSection from "../components/AIComponents/QuestionAnswering/QuestionAnsweringTestSection.vue";
import CommonCarousel from "../components/CommonCarousel.vue";
import ContainerSection from "../components/ContainerSection.vue";
import Footer from "../components/Home/Footer.vue";
import DetailsCard from "../components/Products/DetailsCard.vue";
import QuestionDropDown from "../components/QuestionComponens/QuestionDropDown.vue";
import DataServies from "../services/DataService";
import MessageService from "../services/MessageService";
import SessionService from "../services/SessionService";
import UrlPathService from "../services/UrlPathService";

export default {
  title: "Magure - Chat GPT-3",

  components: {
    QuestionAnsweringTestSection,
    ContainerSection,
    Footer,
    DetailsCard,
    QuestionDropDown,
    CommonCarousel
  },
  data() {
    return {
      applicationCards: DataServies.applicationCardsData,
      isLoading: false,
      responseTime: null,
      responseData: null,
      userRequest: null,
      questionsData: DataServies.AIQuestion
    };
  },

  methods: {
    questionAnswer(event) {
      this.userRequest = event;
      this.isLoading = true;
      UrlPathService.questionAnswer(event)
        .then(response => {
          const response_data = response.data;
          const isValid = response_data.response_data;
          if (response_data.response_code === 0) {
            MessageService.show(response_data.description);
            this.isLoading = false;
          }
          if (isValid.is_vaild === false) {
            SessionService.clearEmailId();
            SessionService.clearPasscode();
            this.isLoading = false;
          }
          if (response_data.response_code === 1) {
            let data = response_data;
            this.responseData = data.response_data;
            this.responseTime = data.time_taken;
          }
          this.isLoading = false;
        })
        .catch(err => {
          let errorMsg = err.message
            ? err.message
            : "Some thing went wrong, Please Try again";
          MessageService.show(errorMsg);
          this.isLoading = false;
        });
    }
  }
};
</script>

<style>
</style>