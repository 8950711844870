<template>
  <div class="my-16 row">
    <div class="mx-auto col-sm-12 col-md-12 col-lg-7 qus-ans-input-section">
      <div>
        <question-answering-input-question
          v-on:selected-input="inputQuestion($event)"
          :isLoading="isLoading"
        />
      </div>
      <div>
        <question-answering-input-context
          v-on:selected-input="inputContext($event)"
          :isLoading="isLoading"
        />
      </div>
      <email-input-modal
        class="absolute"
        v-bind:is-visible.sync="showEmailInputModal"
        v-on:valid-email="onEmailSubmit($event)"
        v-on:close="showEmailInputModal=false"
      />

      <passcode-input-modal
        class="absolute"
        v-bind:is-visible.sync="showPasscodeModal"
        v-on:valid-passcode="onPassCodeSubmit()"
        :email="emailAddress"
        v-on:close="showPasscodeModal=false"
        v-on:invalid-passcode="showPasscodeModal=false"
      />
      <div>
        <base-button
          :isConfirm="true"
          class="btn btn-primary mx-auto"
          :state="submitButtonState"
          @click="onSubmit()"
        >Submit</base-button>
      </div>
    </div>

    <div class="my-5 text-center col-sm-12 col-md-12 col-lg-5" v-if="result && isResultTag">
      RESULT
      <question-answering-result
        :isAnswerTagVisible="true"
        v-bind:receivedResult="result[0].answer"
        :title="'Result'"
      />
      <question-answering-result v-bind:receivedResult="requestMade" :title="'Request'" />
      <question-answering-result v-bind:receivedResult="result[0]" :title="'Response JSON'" />
    </div>
  </div>
</template>

<script>
import ButtonState from "../../../lib/button-state";
import SessionService from "../../../services/SessionService";
import PasscodeInputModal from "../../AIComponents/PasscodeInputModal.vue";
import BaseButton from "../../BaseButton.vue";
import EmailInputModal from "../../Products/EmailInputModal.vue";

import QuestionAnsweringInputQuestion from "./QuestionAnsweringInputQuestion.vue";
import QuestionAnsweringInputContext from "./QuestionAnsweringInputContext.vue";
import QuestionAnsweringResult from "./QuestionAnsweringResult.vue";
import MessageService from "../../../services/MessageService";

export default {
  components: {
    QuestionAnsweringInputQuestion,
    QuestionAnsweringInputContext,
    BaseButton,
    EmailInputModal,
    PasscodeInputModal,
    QuestionAnsweringResult
  },
  data() {
    return {
      context: "",
      question: "",
      submitButtonState: ButtonState.Ready,
      showEmailInputModal: false,
      showPasscodeModal: false,
      emailAddress: "",
      isEmailGiven: Boolean,
      isPasscodeGiven: Boolean,
      responseTime: null,
      responseData: null,
      isResultTag: false
    };
  },

  methods: {
    inputContext(e) {
      this.context = e;
    },

    inputQuestion(e) {
      this.question = e;
    },

    onEmailSubmit(event) {
      this.emailAddress = event.email;
      this.showEmailInputModal = false;
      this.$emit("on-email-submit", this.emailAddress);
      this.showPasscodeModal = true;
    },
    onPassCodeSubmit() {
      this.showPasscodeModal = false;

      let data = {
        question: this.question,
        context: this.context,
        email_id: SessionService.getClientEmaiId(),
        passcode: SessionService.getPasscode()
      };
      this.$emit("on-passcode-valid", data);
    },

    onSubmit() {
      if (
        this.question != null &&
        this.context != null &&
        this.question != "" &&
        this.context != ""
      ) {
        this.isEmailGiven = SessionService.getIsCurrentClientGivenEmail();
        this.isPasscodeGiven = SessionService.getIsPasscodePresent();
        if (this.isEmailGiven && this.isPasscodeGiven) {
          this.submitButtonState = ButtonState.Loading;
          this.onPassCodeSubmit();
          this.isResultTag = true;
        } else {
          this.showEmailInputModal = true;
        }
      } else {
        MessageService.show(
          this.question == "" ? "question is empty!" : "context is empty!"
        );
        this.isResultTag = false;
      }
    }
  },
  watch: {
    isLoading: function(newVal) {
      if (newVal === false) {
        this.submitButtonState = ButtonState.Ready;
      }
    }
  },

  props: {
    result: Array,
    requestMade: Object,
    isLoading: Boolean
  }
};
</script>

<style>
.qus-ans-input-section {
  width: 70%;
  display: flex;
  flex-direction: column;
}
</style>