<template>
  <div>
    <div class="mt-5">
      <div class="pr-3">
        <!-- input section -->
        <div class="inputURLSection sm:pl-3 w-100 mt-2 relative">
          <form class="mt-3">
            <label
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-once="true"
              for="urlInput"
            >Context</label>
            <div class="input-group mb-3 text-small">
              <textarea
                data-aos="fade-up"
                data-aos-delay="450"
                data-aos-once="true"
                rows="10"
                ref="fileInput"
                v-model="inputContext"
                type="text"
                class="form-control"
                placeholder="Please input your context"
                maxlength="999"
                aria-label="imageurl"
                @change.prevent="onUrlSubmit()"
              />
            </div>
          </form>
        </div>
        <!-- end of input url -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      contextData: "",
      inputContext: ""
    };
  },
  methods: {
    onUrlSubmit() {
      this.contextData = this.inputContext;
      this.$emit("selected-input", this.contextData);
    }
  },
  props: {
    modelinput: Object,
    isLoading: Boolean
  }
};
</script>

<style lang="scss" scoped>
::placeholder {
  font-size: 12px;
}
input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
@media (max-width: 500px) {
  .inputURLSection {
    margin-top: 1em;
  }
}
</style>