<template>
  <div>
        <div class="result-heading flex p-2 justify-between" v-on:click="toggle()">
			<h4 class="result-name py-2"> {{title}}</h4>
			<button class="btn btn-transparent">
				<caret-down-icon v-if="!isCollapsed" v-bind:alt="'Open '" v-bind:title="'Open '"></caret-down-icon>
				<caret-up-icon v-else v-bind:alt="'Close '" v-bind:title="'Close '"></caret-up-icon>
			</button>
		</div>
          <div class="text-left relative p-2" v-if="isCollapsed && isAnswerTagVisible">
            <h6> Answer : {{receivedResult}}</h6>
        </div>
        <div class="text-left relative p-2" :class="[title =='Request' ? 'h-46': 'h-50',receivedResult? 'overflow-auto' : '',] " v-if="isCollapsed && !isAnswerTagVisible">
            <pre>{{ JSON.stringify(receivedResult, null, '\t') }}</pre>
        </div>
  </div>
</template>

<script>
	import CaretDownIcon from '../../../icons/CaretDownIcon.vue';
	import CaretUpIcon from '../../../icons/CaretUpIcon.vue';
export default {
    
    components: {
        CaretDownIcon,
        CaretUpIcon
    },
    data() {
        return {
            isCollapsed: Boolean
        }
    },
    methods: {
        toggle() {
            this.isCollapsed = !this.isCollapsed;
        }
    },
    watch: {
        receivedResult: function(newval) {
            if(newval !== null) {
                this.isCollapsed = true;
            }
        }
    },

    props: {
        title: String,
        receivedResult: Object,
        isAnswerTagVisible:Boolean
    }
}
</script>

<style lang="scss" scoped>
    .result-heading {
        background: whitesmoke;
    }
</style>